import React from 'react'
import { gql } from '@apollo/client'
import {
  Button,
  Decorator,
  Heading,
  match,
  P,
  Section,
  ThemeProvider,
} from '@klickmarketing/react-components'
import { Box, Container, Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { graphql } from 'gatsby'
import {
  Link as I18nLink,
  Trans,
  useI18next,
} from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

import { DEFAULT_LOCALE } from '../../../languages'
import ContentfulImageWrapper from '../../components/ContentfulImage/ContentfulImageWrapper'
import Layout from '../../components/Layout/Layout'
import { useQueryLatestData } from '../../hooks/useQueryLatestData'

const CollectionsTemplate = () => {
  const { language, t } = useI18next()
  const { latestData } = useQueryLatestData(GET_COLLECTIONS_PAGE_DATA, {
    variables: { locale: language || DEFAULT_LOCALE },
  })

  const collections =
    latestData?.collectionsPageCollection?.items[0]?.collectionsCollection
      ?.items

  return (
    <Layout
      seoProps={{
        title: t('Collections', { ns: 'collections' }),
        description: t(
          'Take a deep dive into topics like COVID-19, Privacy, and Exponential Medicine from multiple perspectives with our curated collections.',
          { ns: 'collections' }
        ),
      }}
    >
      <Box minHeight="100vh">
        <ThemeProvider themeType="onWhite">
          <Container maxWidth="lg">
            <StyledHeadingSection>
              <Box flex="1" p={2}>
                <Heading component="h1" variant="h1">
                  <Trans ns="collections">Collections</Trans>
                </Heading>
              </Box>
              <Box
                flex="1"
                p={2}
                display="flex"
                flexDirection="column"
                alignItems="inherit"
                justifyContent="inherit"
              >
                <StyledDecorator />
                <P component="h2" variant="h6">
                  <Trans ns="collections">
                    Take a deep dive into a single topic from multiple
                    perspectives with our curated collections.
                  </Trans>
                </P>
              </Box>
            </StyledHeadingSection>
          </Container>
          <StyledSection>
            <Container maxWidth="lg">
              <CollectionsContainer>
                <Grid container direction="row" spacing={4}>
                  {latestData
                    ? collections.map(({ sys, ...rest }) => {
                        return (
                          <Grid item xs={12} sm={6} md={3} lg={3} key={sys.id}>
                            <Collection {...rest} />
                          </Grid>
                        )
                      })
                    : [...Array(8).keys()].map((key) => (
                        <Grid item xs={12} sm={6} md={3} lg={3} key={key}>
                          <Skeleton variant="rect" width="100%" height="30vh" />
                        </Grid>
                      ))}
                </Grid>
              </CollectionsContainer>
            </Container>
          </StyledSection>
        </ThemeProvider>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "collections"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

const GET_COLLECTIONS_PAGE_DATA = gql`
  query GetCollectionsPageData($locale: String!, $preview: Boolean) {
    collectionsPageCollection(
      preview: $preview
      where: { slug: "collections" }
      limit: 1
      locale: $locale
    ) {
      items {
        collectionsCollection(limit: 30) {
          items {
            sys {
              id
            }
            title
            description
            slug
            image {
              focalPoint
              image {
                url
                description
                width
                height
              }
            }
            thumbImage {
              focalPoint
              image {
                url
                description
                width
                height
              }
            }
          }
        }
      }
    }
  }
`

const Collection = ({ title, description, image, thumbImage, slug }) => {
  const link = `/collections/${slug}`
  const listImg = thumbImage ? thumbImage : image
  return (
    <CollectionTile to={link}>
      <Box>
        <Link>
          <CollectionHeading variant="subtitle1" component="h3">
            {title}
          </CollectionHeading>
        </Link>
      </Box>
      <ImageContainer>
        <Image
          src={listImg?.image?.url}
          alt={listImg?.image?.description}
          focalPoint={listImg?.focalPoint.focalPoint}
          width={listImg?.image?.width}
          height={listImg?.image?.height}
        />
      </ImageContainer>
      <Content
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="space-between"
      >
        <Description variant="body1">{description}</Description>
        <Box mt={1}>
          <CollectioButton size="large" color="secondary">
            <Trans ns="collections">View Collection</Trans>
          </CollectioButton>
        </Box>
      </Content>
    </CollectionTile>
  )
}

const StyledHeadingSection = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 64px;
  margin-bottom: 32px;

  ${match.isMD} {
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    text-align: left;
    margin-top: 120px;
    margin-bottom: 38px;
  }
`

const Description = styled(P)`
  font-weight: 500;
`

const StyledSection = styled(Section)`
  background: #f2f2f2;
  margin-top: 0px;
  padding-top: 0px;
`

const Link = styled.div`
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

const Image = styled(ContentfulImageWrapper)`
  height: 100%;
  position: relative;
`

const StyledDecorator = styled(Decorator)`
  border-width: 2px;
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 0px;

  ${match.isMD} {
    margin-top: 16px;
  }
`

const CollectionTile = styled(I18nLink)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  height: 100%;
`

const Content = styled(Box)`
  display: flex;
`

const CollectioButton = styled(Button)`
  width: 100%;

  ${match.isSM} {
    width: unset;
  }

  ${match.isMD} {
    margin-top: 0px;
    width: unset;
  }
`

const ImageContainer = styled(Box)`
  width: 100%;
  position: relative;
  padding-top: 100%;
  max-width: 100%;
  margin-bottom: 32px;
`

const CollectionsContainer = styled.div`
  padding-top: 8px;
  padding-bottom: 0px;

  ${match.isMD} {
    padding-top: 64px;
    padding-bottom: 64px;
  }
`

const CollectionHeading = styled(Heading)`
  padding-bottom: 16px;
  width: 100%;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  height: 2.85em;
  line-height: 1em;
  display: flex;
  align-items: flex-end;
`

export default CollectionsTemplate
